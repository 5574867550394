<script>
import modal from '/~/core/mdl'
import RecognitionHeader from '/~rec/components/recognition/recognition-header.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseHeader from '/~/components/base/header/base-header.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

export default {
  name: 'rec-post-announcement-modal',
  components: {
    RecognitionHeader,
    BaseHeader,
    BaseLink,
    BaseButton,
    BaseMdl,
  },
  methods: {
    close() {
      modal.hide()
    },
  },
}
</script>

<template>
  <base-mdl width="xs" layout="v2">
    <template #header>
      <base-header type="closable" class="text-white">
        <recognition-header icon="rec/round/posts-ann" />
      </base-header>
    </template>

    <div class="text-center">
      <h2 class="leading-tight">
        This Post is an
        <br />
        Announcement!
      </h2>
      <p class="my-5">Announcements are marked as important by your company.</p>

      <base-link
        :to="{
          name: 'rec-feed',
          params: { feedId: 'announcements' },
        }"
        @click="close"
      >
        <b>See all Announcements in your News Room</b>
      </base-link>
    </div>
    <base-button class="mt-[30px]" full-width @click="close">
      Got it
    </base-button>
  </base-mdl>
</template>
